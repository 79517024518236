.button {
  color: var(--text);
  font-size: var(--txt-small);
  background-color: var(--bg);
  border: 1px solid var(--mid);
  border-radius: 5px;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color 200ms ease-in-out;
}

.button:hover {
  background-color: var(--darker);
  color: var(--text-inverted);
}

.button:focus {
  outline: none;
}

.button:active {
  outline: none;
  background-color: var(--darker);
  color: var(--text-inverted);
}

.a {
  color: var(--dark);
}

.a:visited {
  color: var(--darker);
}