.text-editor {
  max-width: var(--app-width);
  width: 100%;
  flex: 1 1 auto;
}

.text-editor-prompt {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

.text-editor-prompt-text {
  flex-grow: 1;
}

.text-editor-prompt-buttons {
  flex-grow: 0;
}

.text-editor-main {
  margin: 10px 0;
}

.text-editor-text-area {
  font-size: var(--txt);
  font-family: monospace;
  width: 100%;
  height: 60vh;
  resize: none;
  padding: 20px 0;
  border: 0px solid transparent;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.text-editor-text-area:focus {
  outline: none !important;
}

.text-editor-commands {
  margin: 10px 0;
}

.text-editor-footer {
  margin: 10px 0;
  font-size: var(--txt-small);
}