.app {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.app-header {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 0;
  align-items: center;
  text-align: left;
  font-family: Helvetica, Arial, sans-serif;
  padding: 20px 0;
  padding-left: var(--page-side-margins);
  padding-right: var(--page-side-margins);
}

.app-header-logo-container {
  font-size: var(--txt-large);
  width: 100%;
  max-width: var(--app-width);
  color: var(--darker);
}

.app-header-logo {
  width: 70px;
}

.app-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding-left: var(--page-side-margins);
  padding-right: var(--page-side-margins);
}

.app-footer {
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  justify-content: center;
  padding: 15px var(--page-side-margins);
  font-size: var(--txt-small);
}

.app-footer-inner {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  max-width: var(--app-width);
}
